/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components), {ProjectCard} = _components;
  if (!ProjectCard) _missingMdxReference("ProjectCard", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Projects"), "\n", React.createElement(ProjectCard, {
    title: "Silicon",
    link: "https://silicon.juanromero.ar",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "This proyect use React JS, Node, Express and MySQL.")), "\n", React.createElement(ProjectCard, {
    title: "Criptos Prize",
    link: "https://criptos.juanromero.ar",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "This proyect use React JS.")), "\n", React.createElement(ProjectCard, {
    title: "Films Site",
    link: "https://peliculas.juanromero.ar",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "This proyect use React JS, LocalStorage.")), "\n", React.createElement(ProjectCard, {
    title: "Business",
    link: "https://misdev.juanromero.ar",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "This proyect use React JS, MySQL.")), "\n", React.createElement(ProjectCard, {
    title: "Another Portfolio",
    link: "https://portafolio.juanromero.ar",
    bg: "linear-gradient(to right, #662D8C 0%, #ED1E79 100%)"
  }, React.createElement(_components.p, null, "This proyect use React JS.")), "\n", React.createElement(ProjectCard, {
    title: "Tennis Tournament",
    link: "https://torneito.com.ar",
    bg: "linear-gradient(to right, #D4145A 0%, #FBB03B 100%)"
  }, React.createElement(_components.p, null, "This proyect use HTML5, CSS.")), "\n", React.createElement(ProjectCard, {
    title: "Exercises Advanced PHP",
    link: "https://portafolio.ar/php_avanzado_utn",
    bg: "linear-gradient(to right, #009245 0%, #FCEE21 100%)"
  }, React.createElement(_components.p, null, "This proyect use PHP, MySQL.")), "\n", React.createElement(ProjectCard, {
    title: "Exercises Intermediate PHP",
    link: "https://portafolio.ar/php_intermedio_utn/noticias.php",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "This proyect use PHP, MySQL.")), "\n", React.createElement(ProjectCard, {
    title: "Contacts",
    link: "https://contact.juanromero.ar",
    bg: "linear-gradient(to right, #D585FF 0%, #00FFEE 100%)"
  }, React.createElement(_components.p, null, "This proyect is build in React + vite using React Routes practice.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
