/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "About"), "\n", React.createElement(_components.p, null, "👋 Hi, I'm a Full Stack Developer from Argentina and I'm currently working as a Junior Full Stack developer (S.E.R.N)."), "\n", React.createElement(_components.p, null, "I am curious about different technologies and continually learning."), "\n", React.createElement(_components.p, null, "I have a good predisposition to teamwork as well as individually, I am proactive, with good communication skills and results-oriented."), "\n", React.createElement(_components.p, null, "I have experience in projects as a freelance programmer and my goal is to join a work team to contribute with my knowledge and learn from others. With the experience I have as a freelancer I developed the ability to solve most of the problems on my own. One way or another I manage to make the code work."), "\n", React.createElement(_components.p, null, "🎓 My tech skills are:"), "\n", React.createElement(_components.p, null, "Full Stack Developer SQL, Express JS, React JS, Node JS"), "\n", React.createElement(_components.p, null, "Experience SQL, Express JS, React JS, Node JS, NextJS, Redux, Javascript ES6, Typescript, React Bootstrap, jQuery, PostgreSQL, PHP, MySQL, HTML5, CSS3, Bootstrap, Flexbox, Sass, Xampp, Workbench, Git, GitHub."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
