/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Get in touch"), "\n", React.createElement(_components.p, null, "Visit ", React.createElement(_components.a, {
    href: "https://portafolio.ar"
  }, "my website"), " or find me on other platforms ", React.createElement(_components.a, {
    href: "https://www.linkedin.com/in/romeroresek/"
  }, "LinkedIn"), " and ", React.createElement(_components.a, {
    href: "https://github.com/romeroresek"
  }, "GitHub")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
